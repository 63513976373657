import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/giraffe-about-town/giraffe-about-town-main.jpg" imageAlt="Giraffe About Town" mdxType="TextMediaSplit">
      <h1>{`Giraffe About Town`}</h1>
      <p>{`“Get ready to look up! Edinburgh Zoo is working with creative producers Wild in Art to present Giraffe About Town – a large-scale public art event in Edinburgh in the summer of 2022.`}</p>
      <p>{`Designed to celebrate Edinburgh’s extraordinary heritage and cultural diversity, our giraffes will be displayed for two months in locations across the capital, highlighting iconic spots and hidden gems from Edinburgh Airport to Leith.” - Giraffe About Town`}</p>
      <p>{`My Giraffe design, sponsored by Expert Sleepers, hit the streets of Edinburgh in July 2022!`}</p>
      <p>{`These beautiful, flightless birds can waddle their way into anyone’s heart. Inspired by Edinburgh Zoo’s daily penguin parade, this sculpture aims to delight and inspire the viewer to learn more about the life and plight of the world’s penguin population. The illustration showcases three species of penguin, Gentoo, King and Rockhopper in their most natural of habitats - the ocean, where they are at their most agile and graceful.`}</p>
      <p>{`‘Penguin Parade’ was auctioned in October 2022 and raised £4,000 for the conservation and educational efforts at Edinburgh Zoo.`}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/giraffe-about-town/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      